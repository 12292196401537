ngx-material-timepicker-container
{

	.timepicker-backdrop-overlay
	{
		z-index: 9999 !important;
	}

	.timepicker-overlay
	{
		z-index: 10000 !important;
	}
}
