@use '@angular/material' as mat;
@import "partials/_variables.scss";
@import "partials/_mixins.scss";

/* ==========================================================
	SETUP
	change base colors in variables.scss to customize theme
=========================================================== */

// palettes
$pcode-palette-primary: (50: lighten($pcode-color-primary,20%), 100:lighten($pcode-color-primary,15%), 200: lighten($pcode-color-primary,10%), 300: lighten($pcode-color-primary,5%), 400: lighten($pcode-color-primary,5%), 500: $pcode-color-primary, 600: darken($pcode-color-primary,5%), 700: darken($pcode-color-primary,10%), 800: darken($pcode-color-primary,15%), 900: darken($pcode-color-primary,20%), A100: #a7ffeb, A200: #64ffda, A400: #1de9b6, A700: #00bfa5, contrast: (50: $black-87-opacity, 100: $black-87-opacity, 200: $black-87-opacity, 300: $black-87-opacity, 400: $black-87-opacity, 500: white, 600: white, 700: white, 800: $white-87-opacity, 900: $white-87-opacity, A100: $black-87-opacity, A200: $black-87-opacity, A400: $black-87-opacity, A700: $black-87-opacity));
$pcode-palette-accent: (50: lighten($pcode-color-accent,20%), 100:lighten($pcode-color-accent,15%), 200: lighten($pcode-color-accent,10%), 300: lighten($pcode-color-accent,5%), 400: lighten($pcode-color-accent,5%), 500: $pcode-color-accent, 600: darken($pcode-color-accent,5%), 700: darken($pcode-color-accent,10%), 800: darken($pcode-color-accent,15%), 900: darken($pcode-color-accent,20%), A100: #ff80ab, A200: #ff4081, A400: #f50057, A700: #c51162, contrast: (50: $black-87-opacity, 100: $black-87-opacity, 200: $black-87-opacity, 300: $black-87-opacity, 400: $black-87-opacity, 500: white, 600: white, 700: white, 800: $white-87-opacity, 900: $white-87-opacity, A100: $black-87-opacity, A200: $black-87-opacity, A400: $black-87-opacity, A700: $black-87-opacity));
$pcode-palette-warn: (50: lighten($pcode-color-warn,20%), 100:lighten($pcode-color-warn,15%), 200: lighten($pcode-color-warn,10%), 300: lighten($pcode-color-warn,5%), 400: lighten($pcode-color-warn,5%), 500: $pcode-color-warn, 600: darken($pcode-color-warn,5%), 700: darken($pcode-color-warn,10%), 800: darken($pcode-color-warn,15%), 900: darken($pcode-color-warn,20%), A100: #ff8a80, A200: #ff5252, A400: #ff1744, A700: #d50000, contrast: (50: $black-87-opacity, 100: $black-87-opacity, 200: $black-87-opacity, 300: $black-87-opacity, 400: $black-87-opacity, 500: white, 600: white, 700: white, 800: $white-87-opacity, 900: $white-87-opacity, A100: $black-87-opacity, A200: $black-87-opacity, A400: $black-87-opacity, A700: $black-87-opacity));

// theme configuration
$pcode-theme-primary: mat.m2-define-palette($pcode-palette-primary, 500);
$pcode-theme-accent: mat.m2-define-palette($pcode-palette-accent, 500);
$pcode-theme-warn: mat.m2-define-palette($pcode-palette-warn, 500);

// theme setup
$pcode-theme: mat.m2-define-light-theme(( color: ( primary: $pcode-theme-primary, accent: $pcode-theme-accent, warn: $pcode-theme-warn), typography: mat.m2-define-typography-config(), density: 0 ));
@include mat.all-component-themes($pcode-theme);

// safe area inset
body.safe-area-enabled {

	.safe-area-wrapper {
		min-height: calc(100% + env(safe-area-inset-top));
		padding: env(safe-area-inset-top) env(safe-area-inset-right) env(safe-area-inset-bottom) env(safe-area-inset-left);
	}

	.application-header {
		padding: calc(var(--default-header-padding-y) + env(safe-area-inset-top)) calc(var(--default-header-padding-x) + env(safe-area-inset-right)) var(--default-header-padding-y) calc(var(--default-header-padding-x) + env(safe-area-inset-left));

		&:before {
			background-image: linear-gradient(180deg, $color-deep-space-blue env(safe-area-inset-top), #fafafa env(safe-area-inset-top), #fafafa);
		}
	}

	.application-container {
		/*padding-top: calc(var(--header-height) + env(safe-area-inset-top));
		padding-bottom: calc(var(--navbar-height) + env(safe-area-inset-bottom));*/

		&.top-background:before {
			padding-top: env(safe-area-inset-top);
		}

		.page-actions-wrapper {
			bottom: calc(var(--navbar-height) + 1rem + env(safe-area-inset-bottom));
		}
	}

	.bottom-navbar {
		padding: 0 env(safe-area-inset-right) env(safe-area-inset-bottom) env(safe-area-inset-left);
	}

	.cdk-global-overlay-wrapper {
		min-height: calc(100% + env(safe-area-inset-top));
		padding: env(safe-area-inset-top) env(safe-area-inset-right) env(safe-area-inset-bottom) env(safe-area-inset-left);
	}
}

// webapp styles
body {
	color: $color-black;
	font-family: $font-family-body;
	font-size: $font-size-default;
	background: #eef5f9;
}

pcode-root[aria-hidden="true"] #app_wrapper,
pcode-root[aria-hidden="true"] .content_inner_wrapper {
	filter: blur(3px);
	overflow: hidden;
}

.content_inner_wrapper {
	min-height: 100vh;
}

#ajax-loader {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	z-index: 9999;
}

.rotating {
	@include rotating;
}

mat-error {
	font-size: 12px;
}

.application-container {
	--header-height: 75px;
	--navbar-height: 74px;
	height: 100vh;
	overflow: hidden scroll;
	position: relative;
	padding-top: var(--header-height);
	padding-bottom: var(--navbar-height);

	&.top-background:before {
		content: '';
		position: absolute;
		box-sizing: content-box;
		top: 0px;
		left: -40px;
		right: -40px;
		height: 270px;
		background: $color-primary;
		background: -webkit-linear-gradient(135deg,$color-secondary,$color-primary);
		box-shadow: inset 0 -10px 30px -5px rgba(0,0,0,0.25);
		border-radius: 0 0 100% 100%;
		z-index: -1;
	}

	.page-actions-wrapper {
		position: fixed;
		z-index: 10;
		bottom: calc(var(--navbar-height) + 1rem);
		right: 0.75rem;
	}

	.application-header {
		--default-header-padding-y: 15px;
		--default-header-padding-x: 30px;
		display: flex;
		align-items: center;
		justify-content: flex-start;
		flex-wrap: nowrap;
		position: fixed;
		top: 0;
		left: 0;
		width: 100%;
		padding: var(--default-header-padding-y) var(--default-header-padding-x);
		/*height: 75px;*/
		background: none;
		border: none;
		box-shadow: none;
		z-index: 1000;

		&.application-header-dark {
			> .application-header-title,
			> button {
				color: $color-deep-space-blue;
			}
		}

		&:before {
			content: '';
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			background-color: #fafafa;
			box-shadow: 0 -2px 30px -10px rgba(0, 0, 0, 0.1);
			transform: translateY(-100%);
			transition: transform .6s ease;
			z-index: -1;
		}

		> .application-header-title {
			display: flex;
			flex-direction: column;
			justify-content: center;
			font-size: 26px;
			line-height: 34px;
			text-align: center;
			flex: 1;
			color: #fff;
			transition: color 0.6s ease;

			span {

				@include ellipsis;
			}

			small {
				font-size: 11px;
				line-height: 18px;
				@include ellipsis;
			}
		}

		> img.application-header-logo {
			display: block;
			height: 56px;
			align-self: center;
		}

		> button {
			width: 45px;
			height: 45px;
			padding: 0;
			color: #fff;
			transition: color 0.6s ease;

			.mat-icon {
				font-size: 30px;
				line-height: 45px;
			}
		}

		&.header-full {
			&:before {
				transform: translateY(0%);
			}

			> .application-header-title,
			> button {
				color: $color-deep-space-blue;
			}
		}
	}

	.application-page-header {
		padding: 20px 10px 15px;
		text-align: center;

		h1 {
			color: #fff;
			line-height: 2rem;

			span {
				font-size: 1.5rem;
				font-weight: 800;
				letter-spacing: 1.2px;
			}

			small {
				display: block;
				font-weight: 400;
				font-size: 12px;
				line-height: 12px;
			}
		}
	}

	.application-body {
		position: relative;
		min-height: calc(100vh - 75px);
		margin: 5px 10px 0;

		&.raised-top {
			background: #eef5f9;
			border-radius: 25px 25px 0 0;
			padding: 30px 10px;

			&:before {
				content: '';
				position: absolute;
				top: 0;
				left: 0;
				right: 0;
				height: 180px;
				border-radius: 40px;
				z-index: -1;
				box-shadow: 0 2px 40px -5px rgba(0, 0, 0, 0.17);
			}
		}

		.section-title {
			span {
				vertical-align: middle;
				font-size: 1rem;
				line-height: 1.5rem;
				font-weight: bold;
				letter-spacing: 0.025em;
				color: #333;
			}

			mat-icon {
				vertical-align: middle;
				font-size: 22px;
				@include text-gradient-primary;
			}
		}
	}

	.application-subtitle {
		display: block;
		max-width: 100%;
		margin: 0 auto 40px auto;
		position: relative;
		padding-bottom: 15px;
		border-bottom: 1px solid $color-light-gray;
		text-align: center;

		h3 {
			display: flex;
			justify-content: center;
			margin: 0;
			color: $color-blue;

			> * {
				align-self: center;
			}

			> mat-icon {
				color: $color-gray;
			}
		}

		&:before {
			display: block;
			position: absolute;
			left: 50%;
			font-family: "Material Icons Round";
			content: "\e5c5";
			bottom: -24px;
			font-size: 40px;
			line-height: 40px;
			margin-left: -20px;
			width: auto;
			height: auto;
			color: $color-light-gray;
		}
	}

	.breadcrumbs-list {
		display: flex;
		justify-content: center;
		list-style: none;
		margin: 10px auto;
		padding: 0;
		font-size: 16px;
		white-space: nowrap;
		overflow: hidden;

		> li {
			display: flex;

			> a {
				display: block;
				align-self: center;
				color: $color-black;
				opacity: 0.6;
			}

			> mat-icon {
				align-self: center;
				margin: 0 5px;
				font-size: 12px;
				width: 12px;
				height: 12px;
			}
		}
	}

	.divider {
		position: relative;
		height: 1px;
		margin: 50px 0;

		&:before {
			content: "";
			position: absolute;
			top: 0;
			left: 5%;
			right: 5%;
			width: 90%;
			height: 1px;
			background-image: linear-gradient(to right, transparent, $color-gray, transparent);
		}

		&:after {
			content: "";
			position: absolute;
			z-index: 1;
			top: -9px;
			left: calc(50% - 9px);
			width: 18px;
			height: 18px;
			background-color: $color-primary;
			border: 1px solid $color-primary;
			border-radius: 50%;
			box-shadow: inset 0 0 0 2px white, 0 0 0 4px white;
		}
	}

	.mat-expansion-panel-header {
		padding: 0 15px;
	}

	.mat-expansion-panel-body {
		padding: 0 15px 15px;
	}

	mat-icon {
		width: auto;
		height: auto;
	}

	@media(max-width:767px) {
		--header-height: 65px;
		--navbar-height: 54px;

		&.top-background:before {
			height: 200px;
		}

		.application-header {
			--default-header-padding-y: 10px;
			--default-header-padding-x: 10px;
			/*height: 65px;*/

			.application-header-title {
				font-size: 18px;
				line-height: 24px;
			}
		}

		.application-body {
			min-height: calc(100vh - 65px);

			&.raised-top {
				padding: 30px 10px;
			}
		}

		.application-subtitle {
			max-width: 100%;
			margin: 0 auto 24px auto;

			h3 {
				font-size: 20px;
			}
		}

		.breadcrumbs-list {
			display: none;
		}
	}
}

.pwa-paginator {
	display: flex;
	justify-content: center;
	margin-top: 30px;

	::ng-deep {
		mat-paginator {
			background: $color-silver-gray;
			padding: 0 10px;
			border-radius: 100px;
			font-size: 14px;
			@include box-shadow-inset-small;

			.mat-paginator-container {
				padding: 0;

				.mat-paginator-page-size {
					display: none;
				}

				.mat-paginator-range-actions {
					background: #fff;
					border-radius: 100px;

					button {
						margin-right: 10px;

						&:last-child {
							margin-right: 0;
						}
					}

					button:not(.mat-button-disabled) {
						background: $color-accent;
						color: #fff;
					}
				}
			}
		}
	}
}

.empty-page {
	padding: 50px 0;
	display: flex;
	flex-direction: column;
	justify-content: center;

	img {
		display: block;
		max-width: 200px;
		margin: 0 auto 30px auto;
	}

	h1 {
		color: $color-gray;
		text-align: center;
		font-size: 24px;
	}

	h2 {
		text-align: center;
		font-size: 20px;
	}

	button {
		margin-top: 30px;
	}

	@media(max-width:767px) {
		img {
			max-width: 100px;
		}
	}
}

.pwa-dialog-overlay-wrapper {
	background: #00000055;
}

.pwa-dialog-panel {
	mat-dialog-container {
		.mdc-dialog__surface {
			@include border-radius-10;
			background: none;

			&:before {
				background-color: #ffffffdd;
				background: linear-gradient(18deg, #e2eff7dd, #ffffffdd);
				backdrop-filter: blur(10px);
				z-index: -1;
			}
		}

		form.webapp-form {
			display: flex;
			flex-direction: column;
			min-height: 100%;
		}

		mat-dialog-content.mat-mdc-dialog-content {
			flex: 1;
			padding-top: 25px;
			padding-bottom: 25px;
		}

		.mat-mdc-dialog-title {
			color: #516073;
			background: #E3ECF7cc;
			display: flex;
			align-items: center;
			margin: 0 2px 0 0;
			padding: 0 24px;
			height: 60px;
			font-size: 1.1rem;
			line-height: 1.4rem;
			font-family: "Poppins", sans-serif;
			font-weight: normal;

			> i, span, mat-icon {
				align-self: center;
			}

			> i, mat-icon {
				margin-right: 10px;
			}

			button[mat-dialog-close] {
				display: block;
				background: $color-accent;
				height: 100%;
				padding: 0 18px;

				mat-icon {
					width: auto;
					height: auto;
					margin: 0;
					font-size: 28px;
					line-height: 20px;
					color: #fff;
				}
			}

			&::before {
				content: none;
			}
		}

		.mat-mdc-dialog-actions {
			margin: 0 10px 10px 10px;

			button {
				flex: 1;
			}
		}
	}
}

.light-dialog-overlay-wrapper {
	background: rgba(255,255,255,0.7);
}

/* layer menu dialog*/
.pwa-layer-menu-dialog-panel {
	mat-dialog-container {
		padding: 0;
		background: none;
		display: flex;
		justify-content: center;
	}
}

/* form */
.webapp-form {
	.form-group {
		margin: 10px 0;
		padding: 10px 0;
	}

	.form-separator {
		border-top: 1px dashed #bbb;
		margin: 20px 0;
	}

	.form-title {
		display: flex;
		color: $color-light-black;
		border-bottom: 1px dashed $color-light-gray;
		padding-bottom: 10px;

		mat-icon {
			font-size: 18px;
			height: 15px;
			width: 15px;
		}

		> * {
			align-self: center;
		}
	}

	.mat-form-field-appearance-outline {
		.mat-form-field-wrapper {
			padding-bottom: 0;
			margin-bottom: 0;

			.mat-form-field-subscript-wrapper {
				margin-top: 26px;
			}
		}
	}

	.mat-form-field-appearance-outline.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label {
		font-size: 18px;

		.mat-form-field-required-marker {
			color: $color-danger;
		}
	}

	&.webapp-form-readonly {
		.form-group {
			margin: 5px 0;
			padding: 5px 0;
		}
	}

	.mat-form-field-appearance-outline.mat-form-field-disabled,
	.mat-form-field-appearance-outline.mat-select-disabled,
	.readonly-field {
		border: 1px solid #ddd;
		border-bottom-width: 3px;
		border-radius: 5px;
		padding: 15px 15px 10px 15px;

		.mat-form-field-wrapper {
			padding: 0;
			margin: 0 0;

			.mat-form-field-infix {
				padding-top: 8px;
				padding-bottom: 0;
			}

			input,
			textarea,
			.mat-select-min-line {
				color: #212529;
			}

			.mat-select-arrow {
				color: transparent !important;
			}


			.mat-form-field-flex {
				padding: 0;
			}

			.mat-form-field-outline,
			.mat-form-field-suffix {
				display: none;
			}
		}
	}

	.custom-form-radio-field {
		margin-bottom: 12px;

		& > label {
			font-size: 14px;
			line-height: 14px;
			padding: 8px 0;
			color: rgba(0, 0, 0, 0.6);
			display: block;
		}

		mat-radio-group {
			position: relative;
			display: flex;
			justify-content: space-between;
			gap: 1.25rem;
			height: 22px;
			padding: 4px 0;
			box-sizing: content-box;

			mat-radio-button {
				background: #fafafa;
				z-index: 1;

				label {
					color: #607188;
					padding: 0 8px;
				}

				&:not(.mat-mdc-radio-checked) > div {
					opacity: .6;
				}

				.mdc-radio.mdc-radio--disabled .mdc-radio__background {
					.mdc-radio__outer-circle {
						border-style: dotted;
					}
				}
			}

			&:before {
				content: ' ';
				position: absolute;
				top: 50%;
				left: 10px;
				right: 10px;
				border-top: 1px dashed #ddd;
				z-index: 0;
			}
		}

		mat-hint {
			display: block;
			font-family: Roboto, sans-serif;
			font-size: 12px;
			line-height: 20px;
			font-weight: 400;
			letter-spacing: 0.0333333333em;
		}

		mat-error {
			font-size: 12px;
		}
	}
}

button.disabled, button:disabled {
	background-color: #bbb !important;
}

button.mdc-button,
a.mdc-button {
	border-radius: 50px;
}

.mat-form-field {
	width: 100%;

	.mat-form-field-infix {
		width: auto;
	}
}

.filters-box {
	padding-bottom: 30px;

	.webapp-form {
		.form-group {
			margin: 0 0 15px 0;
			padding: 0;
		}
	}
}

.blink {
	animation: blinker 1s linear infinite;
}

@keyframes blinker {
	50% {
		opacity: 0;
	}
}

.pagination {
	display: flex;
	justify-content: space-between;

	button {
		display: flex;

		&:first-child mat-icon {
			margin-right: 10px;
		}

		&:last-child mat-icon {
			margin-left: 10px;
		}

		&.disabled {
			color: #ccc;
		}
	}
}

// snakbar
.mat-snack-bar-handset snack-bar-container.mat-snack-bar-container {
	margin: 10px 10px 20px 10px;
	border-radius: 0;

	.mat-simple-snackbar {
		justify-content: center;
	}

	.mat-simple-snackbar-action {
		display: none;
		/*button
		{
			@include border-radius-100;
			@include box-shadow-inset-small;
			background: #fff;
			color: $color-accent;
			outline: none;
		}*/
	}

	&.snackbar--success {
		background: $color-primary;
		color: #116744;
	}

	&.snackbar--error {
		background: $color-danger;
		color: #fff;
	}
}

/* data list */

.data_list {
	display: block;
	list-style: none;
	margin: 0;
	padding: 0 0 0 0;

	> li {
		display: block;
		margin-bottom: 15px;

		> a {
			display: block;
			background: #fff;
			padding: 15px;
			border: 1px solid #ddd;
			border-bottom-width: 3px;
			border-radius: 5px;

			> .data_list_header {
				display: flex;
				justify-content: space-between;
				font-size: 12px;
				text-transform: uppercase;

				> div {
					flex: 1;
				}
			}

			.data_list_title {
				font-weight: bold;
				white-space: nowrap;
				overflow: hidden;
				text-overflow: ellipsis;
				text-transform: uppercase;
			}
		}

		&:nth-child(even) {
			> a {
				background: #f6f6f6;
			}
		}
	}
}

.info_list {
	display: flex;
	flex-wrap: wrap;
	gap: 10px;

	> li {
		display: block;
		color: inherit;
		position: relative;
		background: #fafafa;
		border-radius: 10px;
		border-bottom-width: 5px;
		padding: 10px 15px 25px;
		min-height: 58px;
		flex-grow: 1;

		> mat-icon {
			position: absolute;
			right: 0;
			bottom: 10px;
			opacity: 0.1;
			font-size: 32px;
			width: auto;
			height: auto;
			margin-right: 15px;
		}

		> div {

			> strong {
				display: block;
				font-family: "Poppins", sans-serif;
				font-weight: bold;
				line-height: 1.6rem;
				letter-spacing: .5px;
				color: #333;
			}


			> span {
				display: block;
				color: #bbb;
				font-size: 12px;
				text-transform: uppercase;
				margin-bottom: 10px;
			}
		}

		&:last-child {
			margin: 0;
		}
	}
}

.details_list {
	display: block;
	list-style: none;
	margin: 10px 0 0 0;
	padding: 0;

	> li {
		display: flex;
		justify-content: space-between;
		align-items: center;
		margin-bottom: 5px;
		padding-bottom: 5px;
		border-bottom: 1px dashed #ddd;

		> strong {
			margin-right: 5px;
			color: $color-deep-space-blue;
		}

		> span {
		}
		/*&:last-child {
			margin-bottom: 0;
			padding-bottom: 0;
			border-bottom: 0;
		}*/

		mat-icon {
			margin-right: 8px;
			vertical-align: bottom;
			font-size: 22px;
			line-height: 22px;
			width: auto;
			height: auto;
		}
	}
}

.quickactions {
	display: flex;
	justify-content: center;
	flex-wrap: wrap;

	> div {
		flex: 33%;
		display: flex;

		> button {
			display: block;
			width: 100%;
			text-align: center;
			margin: 5px;
			padding: 15px;
			box-shadow: inset 0 -5px 0 0 rgb(0, 0, 0, 0.2);
			border-radius: 15px;

			> mat-icon {
				display: block;
				font-size: 48px;
				margin: 0 0 10px 0;
				text-align: center;
			}

			> span {
				display: block;
				text-align: center;
				font-size: 12px;
				line-height: 16px;
				text-transform: uppercase;
				font-weight: bold;
			}

			&:disabled {
				opacity: 0.5;
				background: #ddd;
				box-shadow: none;

				> mat-icon, > span {
					color: #676767;
				}
			}
		}
	}
}

.layer_menu_container {
	position: fixed;
	bottom: 60px;
	right: 10px;
	display: flex;
	flex-direction: column-reverse;
	z-index: 100;

	button {
		margin-bottom: 10px;
		-webkit-transition: all 200ms ease;
		-moz-transition: all 200ms ease;
		-ms-transition: all 200ms ease;
		-o-transition: all 200ms ease;
		transition: all 200ms ease;

		> mat-icon {
			align-self: center;
		}

		&.layer_menu_container_trigger {
			z-index: 10;
		}

		&:not(.layer_menu_container_trigger) {
			opacity: 0;
			position: absolute;
			bottom: 0;
			z-index: 9;
		}
	}

	&.layer_menu_container--open {
		.layer_menu_container_trigger {
			-moz-transform: rotate(45deg);
			-webkit-transform: rotate(45deg);
			-o-transform: rotate(45deg);
			-ms-transform: rotate(45deg);
			transform: rotate(45deg);
		}

		> button:not(.layer_menu_container_trigger) {
			opacity: 1;

			&:nth-child(2) {
				bottom: 70px;
			}

			&:nth-child(3) {
				bottom: 140px;
			}
		}
	}
}

/* animated background */
.animated_background {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100vh;
	z-index: -1;
	background: $color-primary;
	background: -webkit-linear-gradient(270deg,$color-secondary, $color-primary);

	.circles {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		overflow: hidden;

		li {
			position: absolute;
			display: block;
			list-style: none;
			width: 20px;
			height: 20px;
			background: rgba(255, 255, 255, 0.2);
			animation: animate 25s linear infinite;
			bottom: -150px;

			&:nth-child(1) {
				left: 25%;
				width: 80px;
				height: 80px;
				animation-delay: 0s;
			}

			&:nth-child(2) {
				left: 10%;
				width: 20px;
				height: 20px;
				animation-delay: 2s;
				animation-duration: 12s;
			}

			&:nth-child(3) {
				left: 70%;
				width: 20px;
				height: 20px;
				animation-delay: 4s;
			}

			&:nth-child(4) {
				left: 40%;
				width: 60px;
				height: 60px;
				animation-delay: 0s;
				animation-duration: 18s;
			}

			&:nth-child(5) {
				left: 65%;
				width: 20px;
				height: 20px;
				animation-delay: 0s;
			}

			&:nth-child(6) {
				left: 75%;
				width: 110px;
				height: 110px;
				animation-delay: 3s;
			}

			&:nth-child(7) {
				left: 35%;
				width: 150px;
				height: 150px;
				animation-delay: 7s;
			}

			&:nth-child(8) {
				left: 50%;
				width: 25px;
				height: 25px;
				animation-delay: 15s;
				animation-duration: 45s;
			}

			&:nth-child(9) {
				left: 20%;
				width: 15px;
				height: 15px;
				animation-delay: 2s;
				animation-duration: 35s;
			}

			&:nth-child(10) {
				left: 85%;
				width: 150px;
				height: 150px;
				animation-delay: 0s;
				animation-duration: 11s;
			}
		}
	}
}

@keyframes animate {

	0% {
		transform: translateY(0) rotate(0deg);
		opacity: 1;
		border-radius: 0;
	}

	100% {
		transform: translateY(-1000px) rotate(720deg);
		opacity: 0;
		border-radius: 50%;
	}
}

.m-t-2 {
	margin-top: 2px;
}

@-webkit-keyframes rotating /* Safari and Chrome */
{
	from {
		-webkit-transform: rotate(0deg);
		-o-transform: rotate(0deg);
		transform: rotate(0deg);
	}

	to {
		-webkit-transform: rotate(-360deg);
		-o-transform: rotate(-360deg);
		transform: rotate(-360deg);
	}
}

@keyframes rotating {
	from {
		-ms-transform: rotate(0deg);
		-moz-transform: rotate(0deg);
		-webkit-transform: rotate(0deg);
		-o-transform: rotate(0deg);
		transform: rotate(0deg);
	}

	to {
		-ms-transform: rotate(-360deg);
		-moz-transform: rotate(-360deg);
		-webkit-transform: rotate(-360deg);
		-o-transform: rotate(-360deg);
		transform: rotate(-360deg);
	}
}

.rotating {
	-webkit-animation: rotating 2s linear infinite;
	-moz-animation: rotating 2s linear infinite;
	-o-animation: rotating 2s linear infinite;
	animation: rotating 2s linear infinite;
}

/* new from migration */

.mat-mdc-form-field {
	width: auto !important;
	display: block !important;
}

.mat-mdc-form-field {
	line-height: 1.5rem;

	&.mat-focused .mat-form-field-ripple {
		height: 1px;
		background-color: #28bebd;
	}

	&.mat-form-field-invalid .mat-form-field-ripple,
	&.mat-form-field-invalid .mat-form-field-ripple.mat-accent {
		background-color: #f44336;
	}
}

body {
	font-family: Open Sans,sans-serif;
	/* link */
	a {
		color: #607188;
	}
	/* form fields */
	.mat-mdc-form-field.mat-form-field-appearance-fill {
		.mdc-text-field {
			padding-left: 0;
			padding-right: 0;

			&.mdc-text-field--filled {
				background-color: transparent;

				.mdc-line-ripple {
					&:before {
						border-bottom-color: rgba(0, 0, 0, 0.12);
					}

					&:after {
						border-bottom-width: 1.5px;
					}
				}

				&.mdc-text-field--disabled {
					.mdc-line-ripple::before {
						border-bottom: 1px dashed rgba(0, 0, 0, 0.42);
					}
				}

				.mdc-text-field__input {
					color: rgb(96,113, 136);
					opacity: 1;
				}

				&.mdc-text-field--focused {
					.mat-mdc-form-field-focus-overlay {
						opacity: 0;
					}

					.mdc-floating-label {
						color: #3f51b5 !important;
					}
				}

				.mdc-floating-label {
					.mat-mdc-form-field-required-marker:after {
						color: #e91e63;
						margin-left: 3px;
					}
				}
			}

			.mat-mdc-form-field-infix {
			}
		}

		&:hover {
			.mat-mdc-form-field-focus-overlay {
				opacity: 0;
			}
		}

		.mat-mdc-form-field-error-wrapper {
			left: auto;
			padding-left: 0;
			padding-right: 0;
		}

		.mat-mdc-form-field-hint-wrapper {
			padding-left: 0;
			padding-right: 0;
		}
	}

	.mat-mdc-text-field-wrapper:not(.mdc-text-field--outlined) .mat-mdc-form-field-infix {
		min-height: unset;
		padding-top: 24px;
		padding-bottom: 6px;
	}
	/* radio */
	.mat-mdc-radio-button {
		.mdc-form-field {
			label {
				cursor: pointer;
				padding-left: 8px;
			}

			.mdc-radio {
				padding: 0;
			}
		}
	}
	/* checkbox */
	.mat-mdc-checkbox {
		.mdc-checkbox {
			padding: 0;

			.mdc-checkbox__background {
				top: 0;
				left: 0;
			}
		}

		label {
			padding-left: 8px;
		}
	}
	/* select */
	.mat-mdc-select {
		.mat-mdc-select-arrow-wrapper {
			transform: translateY(-2px);
		}
	}
	/* button */
	.mdc-button {
		padding-left: 16px;
		padding-right: 16px;
	}
	/* list */
	.mdc-list {
		font-family: "Open Sans", sans-serif;
	}
	/* datepicker */
	mat-datepicker-toggle {
		width: 24px;
		height: 24px;

		.mat-mdc-icon-button.mat-mdc-button-base {
			padding: 0;
			width: inherit;
			height: inherit;
			margin-top: 10px;

			svg {
				width: 20px;
				height: 20px;
			}
		}
	}
}

h1,
h2,
h3,
h4,
h5,
h6 {
	font-family: $font-family-header;
	font-weight: 300;
	line-height: 1.6rem;
}

h1 {
	font-size: rem(27px);
}

h2 {
	font-size: rem(24px);
}

h3 {
	font-size: rem(20px);
}

h4 {
	font-size: rem(18px);
}

p {
	font-size: rem(15px);
	line-height: 1.9em;
}

hr {
	margin-top: 10px;
	margin-bottom: 10px;
}

.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
	font-family: $font-family-header;
}

.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
	color: $header_text_color;
}

a:link,
a:visited {
	color: #688696;
	transition: all 0.5s ease;
}

a:active,
a:hover {
	color: $pcode-color-accent;
}

a:not([href]):not([tabindex]) {
	text-decoration: none;
	cursor: pointer;
}

small {
	font-size: 11px;
	font-weight: 600;
}

/* angular 15 new */

body {
	font-family: $font-family-body;
	/* link */
	a {
		color: $text_color;
	}
	/* form fields */
	.mat-mdc-form-field.mat-form-field-appearance-fill {
		width: auto;
		display: block;

		.mdc-text-field {
			padding-left: 0;
			padding-right: 0;

			&.mdc-text-field--filled {
				background-color: transparent;

				.mdc-line-ripple {
					&:before {
						border-bottom-color: rgba(0, 0, 0, 0.12);
					}

					&:after {
						border-bottom-width: 1.5px;
					}
				}

				&.mdc-text-field--disabled {
					.mdc-line-ripple::before {
						border-bottom: 1px dashed rgba(0, 0, 0, 0.42);
					}
				}

				.mdc-text-field__input {
					color: rgb(96,113, 136);
					opacity: 1;
				}

				&.mdc-text-field--focused {
					.mat-mdc-form-field-focus-overlay {
						opacity: 0;
					}

					.mdc-floating-label {
						color: #3f51b5 !important;
					}
				}

				.mdc-floating-label {
					.mat-mdc-form-field-required-marker:after {
						color: #e91e63;
						margin-left: 3px;
					}
				}
			}

			.mat-mdc-form-field-infix {
			}
		}

		.mat-mdc-form-field-error-wrapper {
			left: auto;
			padding-left: 0;
			padding-right: 0;
		}

		.mat-mdc-form-field-hint-wrapper {
			padding-left: 0;
			padding-right: 0;
		}

		.mat-mdc-text-field-wrapper:not(.mdc-text-field--outlined) {
			.mat-mdc-form-field-infix {
				min-height: unset;
				padding-top: 24px;
				padding-bottom: 6px;
			}
		}

		&:hover {
			.mat-mdc-form-field-focus-overlay {
				opacity: 0;
			}
		}
	}
	/* radio */
	.mat-mdc-radio-button {
		.mdc-form-field {
			label {
				cursor: pointer;
				padding-left: 8px;
			}

			.mdc-radio {
				padding: 0;
			}
		}
	}
	/* checkbox */
	.mat-mdc-checkbox {
		.mdc-checkbox {
			padding: 0;

			.mdc-checkbox__background {
				top: 0;
				left: 0;
			}
		}

		label {
			padding-left: 8px;
		}
	}
	/* select */
	.mat-mdc-select {
		.mat-mdc-select-arrow-wrapper {
			transform: translateY(-2px);
		}
	}
	/* button */
	.mdc-button {
		padding-left: 16px;
		padding-right: 16px;
	}
	/* list */
	.mdc-list {
		font-family: $font-family-body;
	}
	/* datepicker */
	mat-datepicker-toggle {
		width: 24px;
		height: 24px;

		.mat-mdc-icon-button.mat-mdc-button-base {
			padding: 0;
			width: inherit;
			height: inherit;
			margin-top: 10px;

			svg {
				width: 20px;
				height: 20px;
			}
		}
	}
}

/* ### */

.hidden-i {
	display: none !important;
}
