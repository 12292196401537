﻿// tailwind
@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
	:root {
		--tw-color-primary: #024C74;
		--tw-color-secondary: #03688C;
		--tw-color-accent: #077A92;
		--tw-color-warn: #B7192E;
	}
}

// node modules
@import "@angular/material/prebuilt-themes/indigo-pink.css";

// custom
@import "webapp.scss";
@import "custom.scss";
